import { isPlatformBrowser, NgIf, NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  SimpleChange,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import * as uuid from 'uuid';
import { AmaIconComponent } from '../icon/icon.component';

@Component({
    selector: 'ama-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [NgIf, NgClass, AmaIconComponent]
})
export class AmaModalComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public show = false;
  @Input() public ignoreOverlayClick = false;
  @Output() public afterClose = new EventEmitter();
  public newId = '';

  constructor(
    @Inject(PLATFORM_ID) private platform,
    private renderer: Renderer2,
    private el: ElementRef
  ) { }

  public ngOnInit() {
    const myId = uuid.v4();
    this.newId = myId;
    this.el.nativeElement.id = this.newId;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['show'] && this.inputShowChanged(changes['show'])) {
      this.show ? this.onShow() : this.onClose();
    }
  }

  public inputShowChanged = (change: SimpleChange) => change.isFirstChange() ? change.currentValue : change.previousValue !== change.currentValue;

  public onShow = () => {
    this.renderer.setAttribute(this.el.nativeElement, 'id', this.newId);
    this.renderer.appendChild(document.body, this.el.nativeElement);
    this.renderer.addClass(document.body, 'modal-open');
  }

  public onClose = () => {
    this.renderer.removeClass(document.body, 'modal-open');
    this.renderer.removeChild(document.body, this.el.nativeElement);
    this.afterClose.emit();
  }

  public ngOnDestroy() {
    if (isPlatformBrowser(this.platform)) {
      this.newId = '';
      this.onClose();
    }
  }
}
